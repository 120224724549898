@tailwind base;
@tailwind components;
@tailwind utilities;

.react-multi-carousel-list {
  align-items: center;
  padding: 0rem 0 4rem 0;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: rgb(255, 255, 255);
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #4444446a;
    border-radius: 100vh;
    /* border: 3px solid #f6f7ed; */
  }
}
